<template>
  <v-card>
    <v-card-title>
      Users
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-btn @click="createUser" color="primary" class="ml-2">Create User</v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="users"
      item-key="name"
      :search="search"
    >
      <template v-slot:[`item.status`]="{ item }">
        <label v-if="item.status === 'A'">Active</label>
        <label v-if="item.status === 'I'">Inactive</label>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="$router.push({ path: `/vendor/user/${item._id}/edit` })">edit</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "../../services/dataServiceVendor";
const service = new RestResource();
export default {
  data() {
    return {
      users: [],
      search: "",
      headers: [
        { text: "Name", sortable: true, value: "name" },
        { text: "Email", value: "email", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Country Code", value: "countryCode", sortable: true },
        { text: "Mobile Number", value: "mobileNumber", sortable: true },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },

  methods: {
    fetchUsers() {
      service.fetchUsers().then(r => {
        this.users = r.data
        this.$disableLoader();
      });
    },

    createUser() {
      this.$router.push({ name: "VendorCreateUser" });
    },
  },

  mounted() {
    this.$setLoader();
    this.fetchUsers();
  }
};
</script>

<style scoped>
#bt1 {
  float: right;
}
</style>
